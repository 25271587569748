/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

/*Leaflet Map Css*/
@import "leaflet/dist/leaflet.css";

.pac-container {
    z-index: 9999 !important;
}
